import { render, staticRenderFns } from "./i18n-select.vue?vue&type=template&id=72d10ced&"
import script from "./i18n-select.vue?vue&type=script&lang=js&"
export * from "./i18n-select.vue?vue&type=script&lang=js&"
import style0 from "./i18n-select.vue?vue&type=style&index=0&id=72d10ced&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports