import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class KarenzPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);
    this.read = permissionChecker.match(Permissions.values.karenzRead);
    this.create = permissionChecker.match(Permissions.values.karenzCreate);
    this.edit = permissionChecker.match(Permissions.values.karenzEdit);
    this.destroy = permissionChecker.match(Permissions.values.karenzDestroy);
  }
}
