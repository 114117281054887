import authAxios from '@/shared/axios/auth-axios';
export class AuswertungService {
  static async list(filter, orderBy, limit, offset, isExport) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      isExport,
    };
    const response = await authAxios.get(`/auswertung`, {
      params,
    });
    return response.data;
  }

  static async list_Old(filter, orderBy, limit, offset, isExport) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
      isExport,
    };
    const response = await authAxios.get(`/auswertung-old`, {
      params,
    });
    return response.data;
  }
}
