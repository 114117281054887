import { i18n } from '@/i18n';
import IdField from '@/shared/fields/id-field';
import DateTimeRangeField from '@/shared/fields/date-time-range-field';
import DateTimeField from '@/shared/fields/date-time-field';
import { GenericModel } from '@/shared/model/generic-model';
import EnumeratorField from '@/shared/fields/enumerator-field';
import FilesField from '@/shared/fields/files-field';
import Permissions from '@/security/permissions';
import Storage from '@/security/storage';
import DateField from '@/shared/fields/date-field';
import { MitarbeiterField } from '@/modules/mitarbeiter/mitarbeiter-field';
import StringField from '@/shared/fields/string-field';

function label(name) {
  return i18n(`entities.karenz.fields.${name}`);
}

function enumeratorLabel(name, value) {
  return i18n(`entities.karenz.enumerators.${name}.${value}`);
}

const fields = {
  id: new IdField('id', label('id')),
  mitarbeiter: new StringField('mitarbeiter', label('mitarbeiter')),
  geplantenGeburtstermin: new DateField(
    'geplantenGeburtstermin',
    label('geplantenGeburtstermin'),
    {
      required: true,
    },
  ),
  beginn: new DateField('beginn', label('beginn'), {
    required: true,
  }),
  ende: new DateField('ende', label('ende'), {
    required: true,
  }),
  type: new EnumeratorField(
    'type',
    label('type'),
    [
      {
        id: 'mutterschutz',
        label: enumeratorLabel('type', 'mutterschutz'),
      },
      {
        id: 'mutterkarenz',
        label: enumeratorLabel('type', 'mutterkarenz'),
      },
      {
        id: 'elternteilzeit',
        label: enumeratorLabel('type', 'elternteilzeit'),
      },
      {
        id: 'papamonat',
        label: enumeratorLabel('type', 'papamonat'),
      },
      {
        id: 'bildungskarenz',
        label: enumeratorLabel('type', 'bildungskarenz'),
      },
      {
        id: 'bildungsteilzeit',
        label: enumeratorLabel('type', 'bildungsteilzeit'),
      },
    ],
    {
      required: true,
    },
  ),
  karenzvertretungMitarbeiter: MitarbeiterField.relationToOne(
    'karenzvertretungMitarbeiter',
    label('karenzvertretungMitarbeiter'),
    {},
  ),
  karenzvertretungFuerMitarbeiter: MitarbeiterField.relationToOne(
    'karenzvertretungFuerMitarbeiter',
    label('karenzvertretungFuerMitarbeiter'),
    {},
  ),
  bestaetigung: new FilesField(
    'bestaetigung',
    label('bestaetigung'),
    Storage.values.karenzBestaetigung,
    Permissions.getFilePermissions('karenzFileFields', 'karenzBestaetigung'),
    {
      required: true,
      max: 20 * 1024 * 1024,
      formats: ['pdf', 'doc', 'docx'],
    },
  ),
  schutzfrist: new EnumeratorField(
    'schutzfrist',
    label('schutzfrist'),
    [
      {
        id: '8',
        label: enumeratorLabel('schutzfrist', '8'),
      },
      {
        id: '12',
        label: enumeratorLabel('schutzfrist', '12'),
      },
    ],
    {
      required: true,
    },
  ),
  createdAt: new DateTimeField('createdAt', label('createdAt')),
  updatedAt: new DateTimeField('updatedAt', label('updatedAt')),
  createdAtRange: new DateTimeRangeField(
    'createdAtRange',
    label('createdAtRange'),
  ),
};

export class KarenzModel extends GenericModel {
  static get fields() {
    return fields;
  }
}
