import karenzListStore from '@/modules/karenz/karenz-list-store';
import karenzFormStore from '@/modules/karenz/karenz-form-store';
import karenzDestroyStore from '@/modules/karenz/karenz-destroy-store';

export default {
  namespaced: true,

  modules: {
    destroy: karenzDestroyStore,
    form: karenzFormStore,
    list: karenzListStore,
  },
};
