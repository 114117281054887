<template>
  <div class="anstellung">
    <el-col>
      <el-row :gutter="20" v-for="item in karenzTypes" :key="item.id">
        <div v-if="filterKarenzByType(item).length > 0">
          <span>
            <app-i18n
              :code="`entities.karenz.enumerators.type.${item}`"
            ></app-i18n>
          </span>
          <el-table
            :border="true"
            :data="filterKarenzByType(item)"
            row-key="id"
            v-loading="loading"
          >
            <el-table-column width="20">
              <template v-slot="scope">
                <el-badge is-dot :type="getStatusTagType(scope.row)"></el-badge>
              </template>
            </el-table-column>
            <el-table-column
              :label="fields.beginn.label"
              :prop="fields.beginn.name"
              width="200"
            >
              <template v-slot="scope">
                {{ presenter(scope.row, 'beginn') }}
              </template>
            </el-table-column>
            <el-table-column
              :label="fields.ende.label"
              :prop="fields.ende.name"
              width="200"
            >
              <template v-slot="scope">
                {{ presenter(scope.row, 'ende') }}
              </template>
            </el-table-column>
            <el-table-column
              :label="fields.geplantenGeburtstermin.label"
              :prop="fields.geplantenGeburtstermin.name"
              v-if="item == 'mutterschutz'"
            >
              <template v-slot="scope">
                {{ presenter(scope.row, 'geplantenGeburtstermin') }}
              </template>
            </el-table-column>
            <el-table-column
              :label="fields.karenzvertretungMitarbeiter.label"
              :prop="fields.karenzvertretungMitarbeiter.name"
              v-if="item != 'elternteilzeit' || item != 'bildungsteilzeit'"
            >
              <template v-slot="scope">
                <app-list-item-relation-to-one
                  :label="fields.karenzvertretungMitarbeiter.label"
                  :readPermission="
                    fields.karenzvertretungMitarbeiter.readPermission
                  "
                  :url="false"
                  :value="presenter(scope.row, 'karenzvertretungMitarbeiter')"
                ></app-list-item-relation-to-one>
              </template>
            </el-table-column>
            <el-table-column
              :label="fields.bestaetigung.label"
              :prop="fields.bestaetigung.name"
            >
              <template v-slot="scope">
                <app-list-item-file
                  :permissions="fields.bestaetigung.permissions"
                  :value="presenter(scope.row, 'bestaetigung')"
                ></app-list-item-file>
              </template>
            </el-table-column>
            <el-table-column label="Aktion" width="100">
              <template v-slot="scope">
                <div>
                  <el-button
                    @click="$emit('doEdit', scope.row)"
                    type="warning"
                    size="small"
                    circle
                    plain
                    icon="el-icon-edit"
                    v-if="hasPermissionToEdit"
                  ></el-button>
                  <el-button
                    @click="$emit('doDelete', scope.row.id)"
                    type="danger"
                    size="small"
                    circle
                    plain
                    icon="el-icon-delete"
                    v-if="hasPermissionToDestroy"
                  ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-row>
    </el-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { i18n } from '@/i18n';
import { KarenzModel } from '@/modules/karenz/karenz-model';
import { KarenzPermissions } from '@/modules/karenz/karenz-permissions';
const { fields } = KarenzModel;

export default {
  name: 'app-karenz-list-table',

  data() {
    return {
      karenzTypes: [
        'mutterschutz',
        'mutterkarenz',
        'papamonat',
        'elternteilzeit',
        'bildungskarenz',
        'bildungsteilzeit',
      ],
    };
  },

  computed: {
    ...mapGetters({
      rows: 'karenz/list/rows',
      mutterschutz: 'karenz/list/mutterschutz',
      mutterkarenz: 'karenz/list/mutterkarenz',
      bildungskarenz: 'karenz/list/bildungskarenz',
      papamonat: 'karenz/list/papamonat',
      elternteilzeit: 'karenz/list/elternteilzeit',
      bildungsteilzeit: 'karenz/list/bildungsteilzeit',
      count: 'karenz/list/count',
      loading: 'karenz/list/loading',
      pagination: 'karenz/pagination',
      mitarbeiter: 'mitarbeiter/form/id',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),
    fields() {
      return fields;
    },
    hasPermissionToEdit() {
      return new KarenzPermissions(this.currentUser).edit;
    },

    availableTypes() {
      return this.rows
        .map((f) => f.type)
        .filter(
          (value, index, current_value) =>
            current_value.indexOf(value) === index,
        );
    },

    hasPermissionToDestroy() {
      return new KarenzPermissions(this.currentUser).destroy;
    },
  },

  methods: {
    presenter(row, fieldName) {
      return KarenzModel.presenter(row, fieldName);
    },
    filterKarenzByType(item) {
      return this.rows.filter((f) => f.type == `${item}`);
    },
    getStatusTagType(row) {
      if (row.status === 'active') {
        return 'success';
      }
      if (row.status === 'inactive') {
        return 'danger';
      }
      if (row.new) {
        return 'info';
      }
      if (!row.status && !row.new) {
        return 'warning';
      }
      return 'danger';
    },
    i18n(code) {
      return i18n(code);
    },
  },
};
</script>

<style></style>
