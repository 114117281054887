import { SettingsService } from '@/modules/settings/settings-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    findLoading: false,
    saveLoading: false,
    settings: null,
    svWerte: null,
    svWerteActive: null,
  },

  getters: {
    settings: (state) => state.settings,
    svWerte: (state) => state.svWerte,
    svWerteActive: (state) => {
      return state.settings.svWerteActive;
    },
    contractApprover: (state) => state.settings.contractApprover,
    beendigungApprover: (state) => state.settings.beendigungApprover,
    geringfuegigkeitsgrenze: (state) =>
      state.svWerteActive.geringfuegigkeitsgrenze,
    hbglProTag: (state) => state.svWerteActive.hbglProTag,
    hbglProMonat: (state) => state.svWerteActive.hbglProMonat,
    hbglProJahr: (state) => state.svWerteActive.hbglProJahr,
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
  },

  mutations: {
    RESET_SVWERTE(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.record = null;
    },
    FIND_STARTED(state) {
      state.settings = null;
      state.svWerte = null;
      state.svWerteActive = null;
      state.findLoading = true;
    },

    FIND_SUCCESS(state, payload) {
      state.settings = payload;
      state.svWerte = payload.svWerte || null;
      state.findLoading = false;
    },

    FIND_ERROR(state) {
      state.settings = null;
      state.svWerte = null;
      state.svWerteActive = null;
      state.findLoading = false;
    },

    SAVE_STARTED(state) {
      state.saveLoading = true;
    },

    SAVE_SUCCESS(state) {
      state.saveLoading = false;
    },

    SAVE_ERROR(state) {
      state.saveLoading = false;
    },

    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },

    UPDATE_SUCCESS(state) {
      state.saveLoading = false;
    },

    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
  },

  actions: {
    async doFind({ commit }) {
      try {
        commit('FIND_STARTED');
        const settings = await SettingsService.find();
        commit('FIND_SUCCESS', settings);
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/');
      }
    },

    async doSave({ commit }, values) {
      try {
        commit('SAVE_STARTED');
        await SettingsService.save(values);
        commit('SAVE_SUCCESS');
        Message.success(i18n('settings.save.success'));
        routerAsync().push('/');
      } catch (error) {
        Errors.handle(error);
        commit('SAVE_ERROR');
      }
    },

    async doCreateSvWerte({ commit, dispatch }, values) {
      try {
        commit('SAVE_STARTED');
        await SettingsService.createSvWerte(values);
        commit('SAVE_SUCCESS');
        Message.success(i18n('settings.create.success'));
        dispatch('doFind');
      } catch (error) {
        Errors.handle(error);
        commit('SAVE_ERROR');
      }
    },

    async doUpdateSvWerte({ commit, dispatch }, { year, values }) {
      try {
        commit('UPDATE_STARTED');
        await SettingsService.updateSvWerte(year, values);
        commit('UPDATE_SUCCESS');
        dispatch('doFind');
        Message.success(i18n('settings.update.success'));
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doSendEmailTest({ commit }, values) {
      try {
        await SettingsService.sendTestEmail(values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('settings.emailTest.success'));
      } catch (error) {
        Errors.handle(error);
      }
    },
  },
};
