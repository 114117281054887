import dateHelper from '@/shared/plugins/dateHelper';

export default class CalculationHelper {
  constructor(svWerteActive) {
    this.SV_HBGL_PRO_TAG = svWerteActive.hbglProTag;
    this.SV_HBGL_PRO_MONAT = svWerteActive.hbglProMonat;
    this.GERINGFUEGIG_GRENZE = svWerteActive.geringfuegigkeitsgrenze;
    this.SV = svWerteActive.sv;
    this.SV_60_63 = svWerteActive.sv_60_bis_63;
    this.SV_UBER_63 = svWerteActive.sv_uber_63;
    this.SV_GERINGFUEGIG = svWerteActive.sv_geringfuegig;
    this.SV_GERINGFUEGIG_UBER_60 = svWerteActive.sv_geringfuegig_uber_60;
    this.SV_SZ = svWerteActive.sv_sz;
    this.SV_SZ_60_63 = svWerteActive.sv_sz_60_bis_63;
    this.SV_SZ_UBER_63 = svWerteActive.sv_sz_uber_63;
    this.DB = svWerteActive.db;
    this.DB_UBER_60 = svWerteActive.db_uber_60;
    this.DZ = svWerteActive.dz;
    this.BV = svWerteActive.bv;
    this.KoST = svWerteActive.koSt;
  }

  gesamtkosten(
    gesamtbrutto,
    age,
    eintrittsdatum,
    uBahn,
    kommunalsteuer,
    gesamtwochenstunden,
  ) {
    let gesamtkosten = 0;
    let gesamtbruttoRest = 0;
    let hbgl = 0;
    let isFirstMonth = false;
    let sundays = null;
    const isGeringfuegig = gesamtbrutto <= this.GERINGFUEGIG_GRENZE;

    if (gesamtbrutto === 0) {
      return 0;
    }

    if (dateHelper.isInCurrentMonth(eintrittsdatum)) {
      if (dateHelper.isFirstDayInMonth(eintrittsdatum)) {
        hbgl = this.SV_HBGL_PRO_MONAT;
      } else {
        const numberOfDays = dateHelper.getNumberOfDaysInMonth(eintrittsdatum);
        const daysToCalculate =
          numberOfDays - dateHelper.noTimeDate(eintrittsdatum).getDate() + 1; // +1 as date is start day, so that one is paid
        //get HBGL brutto limit
        hbgl = daysToCalculate * this.SV_HBGL_PRO_TAG;
        isFirstMonth = true;
        sundays = dateHelper.getNumberOfWeekDays(
          eintrittsdatum,
          dateHelper.lastDayOfMonth(eintrittsdatum),
        );
      }
    } else {
      hbgl = this.SV_HBGL_PRO_MONAT;
    }

    /* if sum is greater than hbgl
     * it is split to gesamtbrutto of hbgl and gesamtbruttoRest */
    if (gesamtbrutto > hbgl) {
      gesamtbruttoRest = gesamtbrutto - hbgl;
      gesamtbrutto = hbgl;
    }
    //calculate gesamtkosten for brutto less than hbgl
    let percentage = this.getPercentageByAge(
      age,
      isFirstMonth,
      false,
      kommunalsteuer,
      isGeringfuegig,
    );
    gesamtkosten = gesamtbrutto + this._percentage(gesamtbrutto, percentage);

    //if there is gesamtbruttoRest calculate percentage and add to gesamtkosten
    if (gesamtbruttoRest) {
      let percentage2 = this.getPercentageByAge(
        age,
        isFirstMonth,
        true,
        kommunalsteuer,
        isGeringfuegig,
      );
      gesamtkosten +=
        gesamtbruttoRest + this._percentage(gesamtbruttoRest, percentage2);
    }

    // geringfugun ne placa uban, dodat
    // TODO
    // umjesto gesamtbrutto stavit da ako ima gesamtstunde <= 10 sati
    if (uBahn && age < 55 && gesamtwochenstunden > 10) {
      gesamtkosten += this.addUbahnCost(sundays);
    }
    return parseFloat(gesamtkosten.toFixed(2));
  }

  isFirstEmploymentAndStartingFirstOfMonth(date) {
    return (
      dateHelper.isInCurrentMonth(date) && dateHelper.isFirstDayInMonth(date)
    );
  }

  calculateBruttoBasedOnWorkingDays(brutto, date, isKstVon) {
    const tmpDate = dateHelper.noTimeDate(new Date(date));
    const daysInMonth = dateHelper.getNumberOfDaysInMonth(tmpDate);
    let workedDays = 0;
    //get days to pay in this month as start day is not 1th in month

    if (isKstVon) {
      const day = tmpDate.getDate();
      workedDays = dateHelper.getNumberOfDaysInMonth(tmpDate) - day + 1; // +1 as date is start day, so that one is paid
    } else {
      workedDays = tmpDate.getDate() /*  + 1 */;
    }
    return this.calculateBrutto(brutto, daysInMonth, workedDays);
  }

  calculateBruttoBasedOnWorkingDays_ByDays(brutto, von, bis) {
    const workedDays = dateHelper.dateDiffInDays(von, bis) + 1;
    const daysInMonth = dateHelper.getNumberOfDaysInMonth(von);
    return this.calculateBrutto(brutto, daysInMonth, workedDays);
  }

  calculateBrutto(brutto, daysInMonth, workedDays) {
    return parseFloat(((brutto / daysInMonth) * workedDays).toFixed(2));
  }

  sumArrayObjectField(data, field) {
    return data
      .map((f) => {
        if (f[`${field}`]) {
          if (dateHelper.isKstActive(f)) {
            return f[`${field}`];
          }
        }
        return 0;
      })
      .reduce((a, b) => a + b, 0);
  }

  getKstBrutto(f, field) {
    const von = f.von ? dateHelper.noTimeDate(f.von) : null;
    const bis = f.bis ? dateHelper.noTimeDate(f.bis) : null;
    const today = dateHelper.noTimeDate(new Date());
    //if KST status is inactive or in any karenz or is inactive by dates it will not processed
    if (
      f.status &&
      (f.status === 'inactive' ||
        f.status === 'mutterschutz' ||
        f.status === 'mutterkarenz' ||
        f.status === 'bildungskarenz' ||
        f.status === 'papamonat')
    ) {
      return 0;
    }

    if (f.new && !dateHelper.isKstActive(f)) {
      //in a case when there is a new KST and is not active, meaning is planned
      return 0;
    }

    //check if value is set, if not return 0
    if (f[`${field}`]) {
      if (
        /*  (dateHelper.isInFuture(von) &&
              !dateHelper.areDatesInSameMonth(today, von)) || */
        bis &&
        !dateHelper.areDatesInSameMonth(today, bis) &&
        (dateHelper.isInPast(bis) || dateHelper.isToday(bis))
      ) {
        return 0;
      }

      if (dateHelper.isInFuture(von) && !dateHelper.isInCurrentMonth(von)) {
        return 0;
      }

      if (
        dateHelper.isInPast(von) &&
        !dateHelper.areDatesInSameMonth(today, von) &&
        (!bis || !dateHelper.areDatesInSameMonth(today, bis))
      ) {
        return f[`${field}`];
      }
      //if von is 1st day in current month and there is no bis set, brutto is returned (no calculation needed)
      if (
        dateHelper.isInCurrentMonth(von) &&
        dateHelper.isFirstDayInMonth(von) &&
        !bis
      ) {
        return f[`${field}`];
      }
      //if von and bis are in current month we are calculating brutto based on days between von-bis
      if (
        dateHelper.areDatesInSameMonth(von, bis) &&
        dateHelper.isInCurrentMonth(von) &&
        dateHelper.isInCurrentMonth(bis) &&
        !(dateHelper.isFirstDayInMonth(von) && dateHelper.isLastDayInMonth(bis))
      ) {
        return this.calculateBruttoBasedOnWorkingDays_ByDays(
          f[`${field}`],
          von,
          bis,
        );
      }

      const isVon = !(
        (bis && dateHelper.isInPast(bis)) ||
        dateHelper.isToday(bis) ||
        dateHelper.areDatesInSameMonth(von, bis) ||
        dateHelper.isInCurrentMonth(bis)
      );
      const date = isVon ? von : bis;
      return this.calculateBruttoBasedOnWorkingDays(f[`${field}`], date, isVon);
    }
    return 0;
  }

  getGesamtbrutto(data, field) {
    const gesamtbrutto = data
      .map((f) => {
        return this.getKstBrutto(f, field);
      })
      .reduce((a, b) => a + b, 0);

    return parseFloat(gesamtbrutto.toFixed(2), 10);
  }

  addUbahnCost(sundays) {
    //counting by sundays in month
    if (sundays) {
      return sundays * 2;
    }

    // counting for a full month
    const weeksCount = dateHelper.sundaysInMonth();
    if (weeksCount === 4) {
      return 8;
    } else if (weeksCount === 5) {
      return 10;
    }
  }

  getPercentageByAge(
    age,
    isFirstMonth,
    isGesamtbruttoRest,
    kommunalsteuer,
    isGeringfuegig,
  ) {
    let SV_Int = 0;
    let SV_REST = 0;
    let BV_Int = isFirstMonth ? 0 : this.BV;
    let KoST_Int = kommunalsteuer ? this.KoST : 0;

    if (isGeringfuegig) {
      if (age < 60) {
        return this.SV_GERINGFUEGIG + this.DB + this.DZ + KoST_Int + BV_Int;
      } else if (age >= 60) {
        return (
          this.SV_GERINGFUEGIG_UBER_60 +
          this.DB_UBER_60 +
          this.DZ +
          KoST_Int +
          BV_Int
        );
      }
    }

    if (age < 55) {
      SV_Int = isGesamtbruttoRest ? SV_REST : this.SV;
      return SV_Int + this.DB + this.DZ + KoST_Int + BV_Int;
    } else if (age >= 55 && age < 60) {
      SV_Int = isGesamtbruttoRest ? SV_REST : this.SV;
      return SV_Int + this.DB + this.DZ + KoST_Int + BV_Int;
    } else if (age >= 60 && age < 63) {
      SV_Int = isGesamtbruttoRest ? SV_REST : this.SV_60_63;
      return SV_Int + this.DB_UBER_60 + this.DZ + KoST_Int + BV_Int;
    } else if (age >= 63) {
      SV_Int = isGesamtbruttoRest ? SV_REST : this.SV_UBER_63;
      return SV_Int + this.DB_UBER_60 + this.DZ + KoST_Int + BV_Int;
    }
  }

  isEintrittdatumActive(date) {
    return (
      dateHelper.noTimeDate(new Date(date)) <=
      dateHelper.noTimeDate(new Date(date))
    );
  }

  _percentage(num, per) {
    if (isNaN(per)) {
      num = parseFloat(num);
    }
    return (num / 100) * per;
  }
}
