import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class EinstufungPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.einstufungRead);
    this.einstufungAutocomplete = permissionChecker.match(
      Permissions.values.einstufungAutocomplete,
    );
    this.create = permissionChecker.match(Permissions.values.einstufungCreate);
    this.edit = permissionChecker.match(Permissions.values.einstufungEdit);
    this.destroy = permissionChecker.match(
      Permissions.values.einstufungDestroy,
    );
    this.navigate = permissionChecker.match(
      Permissions.values.einstufungNavigate,
    );
    this.import = permissionChecker.match(Permissions.values.einstufungImport);
  }
}
