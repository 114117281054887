import { KarenzService } from '@/modules/karenz/karenz-service';
import Errors from '@/shared/error/errors';
import Message from '@/shared/message/message';
import { routerAsync } from '@/app-module';
import { i18n } from '@/i18n';

export default {
  namespaced: true,

  state: {
    loading: false,
  },

  getters: {
    loading: (state) => !!state.loading,
  },

  mutations: {
    DESTROY_ALL_STARTED(state) {
      state.loading = true;
    },

    DESTROY_ALL_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ALL_ERROR(state) {
      state.loading = false;
    },

    DESTROY_STARTED(state) {
      state.loading = true;
    },

    DESTROY_SUCCESS(state) {
      state.loading = false;
    },

    DESTROY_ERROR(state) {
      state.loading = false;
    },
  },

  actions: {
    async doDestroy({ commit }, id) {
      try {
        commit('DESTROY_STARTED');

        await KarenzService.destroyAll([id]);

        commit('DESTROY_SUCCESS');

        Message.success(i18n('entities.karenz.destroy.success'));

        routerAsync().push('/mitarbeiter');

        /*   dispatch(
          `${'karenz/list'}/doFetch`,
          { filter: rootGetters[`${'karenz/list'}/filter`] },
          {
            root: true,
          },
        ); */
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ERROR');
      }
    },

    async doDestroyAll({ commit, dispatch }, ids) {
      try {
        commit('DESTROY_ALL_STARTED');

        await KarenzService.destroyAll(ids);

        commit('DESTROY_ALL_SUCCESS');

        dispatch(`${'karenz/list'}/doUnselectAll`, null, {
          root: true,
        });

        Message.success(i18n('entities.karenz.destroyAll.success'));

        routerAsync().push('/mitarbeiter');
        /*  dispatch(
          `${'karenz/list'}/doFetch`,
          { filter: rootGetters[`${'karenz/list'}/filter`] },
          {
            root: true,
          },
        ); */
      } catch (error) {
        Errors.handle(error);
        commit('DESTROY_ALL_ERROR');
      }
    },
  },
};
