import Permissions from '@/security/permissions';
import PermissionChecker from '@/modules/user/permission-checker';

export class FunktionPermissions {
  constructor(currentUser) {
    const permissionChecker = new PermissionChecker(currentUser);

    this.read = permissionChecker.match(Permissions.values.funktionRead);
    this.funktionAutocomplete = permissionChecker.match(
      Permissions.values.funktionAutocomplete,
    );
    this.create = permissionChecker.match(Permissions.values.funktionCreate);
  }
}
