import importerStore from '@/shared/importer/importer-store';
import { EinstufungService } from '@/modules/einstufung/einstufung-service';
import einstufungImporterFields from '@/modules/einstufung/einstufung-importer-fields';
import { i18n } from '@/i18n';

export default importerStore(
  EinstufungService.import,
  einstufungImporterFields,
  i18n('entities.einstufung.importer.fileName'),
  i18n('entities.einstufung.importer.hint'),
);
