import einstufungListStore from '@/modules/einstufung/einstufung-list-store';
import einstufungFormStore from '@/modules/einstufung/einstufung-form-store';
import einstufungDestroyStore from '@/modules/einstufung/einstufung-destroy-store';
import einstufungImporterStore from '@/modules/einstufung/einstufung-importer-store';

export default {
  namespaced: true,

  modules: {
    destroy: einstufungDestroyStore,
    form: einstufungFormStore,
    list: einstufungListStore,
    importer: einstufungImporterStore,
  },
};
