import { FunktionService } from '@/modules/funktion/funktion-service';
import RelationToOneField from '@/shared/fields/relation-to-one-field';
import RelationToManyField from '@/shared/fields/relation-to-many-field';
import Permissions from '@/security/permissions';

export class FunktionField {
  static relationToOne(name, label, options) {
    return new RelationToOneField(
      name,
      label,
      '/funktion',
      Permissions.values.funktionRead,
      Permissions.values.funktionNavigate,
      FunktionService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }

  static relationToMany(name, label, options) {
    return new RelationToManyField(
      name,
      label,
      '/funktion',
      Permissions.values.funktionRead,
      Permissions.values.funktionNavigate,
      FunktionService.listAutocomplete,
      (record) => {
        if (!record) {
          return null;
        }

        return {
          id: record.id,
          label: record.name,
        };
      },
      options,
    );
  }
}
