<template>
  <el-aside :width="asideWidth">
    <!--     <h1 class="logo">
      <router-link to="/">
        <app-i18n code="app.title"></app-i18n>
      </router-link>
    </h1> -->

    <div v-if="!collapsed">
      <el-switch style="padding: 15px" v-model="showAdvanced"></el-switch>
    </div>

    <el-menu
      :class="{
        'el-menu-side-nav': true,
        collapsed: collapsed,
      }"
      :collapse="collapsed"
      :collapse-transition="true"
      :router="true"
      @select="collapseMenuIfMobile()"
    >
      <el-menu-item
        :class="classFor('/', true)"
        :route="{ path: '/' }"
        index="/"
      >
        <i class="el-icon-fa-home"></i>
        <span slot="title">
          <app-i18n code="home.menu"></app-i18n>
        </span>
      </el-menu-item>
      <el-menu-item
        :class="classFor('/mitarbeiter')"
        :route="{ path: '/mitarbeiter' }"
        index="/mitarbeiter"
        v-if="hasPermissionToMitarbeiter"
      >
        <i class="el-icon-s-custom"></i>
        <span slot="title">
          <span slot="title">
            <app-i18n code="entities.mitarbeiter.menu"></app-i18n>
          </span>
        </span>
      </el-menu-item>

      <el-menu-item
        :class="classFor('/aenderung')"
        :route="{ path: '/aenderung' }"
        index="/aenderung"
        v-if="hasPermissionToAenderung"
      >
        <i class="el-icon-fa-pencil-square"></i>
        <span slot="title">
          <span slot="title">
            <app-i18n code="entities.aenderung.menu"></app-i18n>
          </span>
        </span>
      </el-menu-item>

      <el-submenu index="1">
        <template slot="title">
          <i class="el-icon-s-data"></i>
          <span>Analyse</span>
        </template>

        <el-menu-item
          :class="classFor('/auswertung')"
          :route="{ path: '/auswertung' }"
          index="/auswertung"
          v-if="hasPermissionToAuswertung"
        >
          <i class="el-icon-s-data"></i>
          <span slot="title">
            <span>Auswertung</span>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/statistik-austria')"
          :route="{ path: '/statistik-austria' }"
          index="/statistik-austria"
          v-if="hasPermissionToStatistikAustria"
        >
          <i class="el-icon-fa-pie-chart"></i>
          <span slot="title">
            <span>
              <app-i18n code="entities.statistikAustria.menu"></app-i18n>
            </span>
          </span>
        </el-menu-item>
      </el-submenu>

      <div v-if="showAdvanced">
        <el-menu-item
          :class="classFor('/verwendung-kostenstelle')"
          :route="{ path: '/verwendung-kostenstelle' }"
          index="/verwendung-kostenstelle"
          v-if="hasPermissionToVerwendungKostenstelle"
        >
          <i class="el-icon-fa-file"></i>
          <span slot="title">
            <app-i18n code="entities.verwendungKostenstelle.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/mandant')"
          :route="{ path: '/mandant' }"
          index="/mandant"
          v-if="hasPermissionToMandant"
        >
          <i class="el-icon-s-home"></i>
          <span slot="title">
            <app-i18n code="entities.mandant.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/kostentraeger')"
          :route="{ path: '/kostentraeger' }"
          index="/kostentraeger"
          v-if="hasPermissionToKostentraeger"
        >
          <i class="el-icon-fa-file"></i>
          <span slot="title">
            <app-i18n code="entities.kostentraeger.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/kostenstelle')"
          :route="{ path: '/kostenstelle' }"
          index="/kostenstelle"
          v-if="hasPermissionToKostenstelle"
        >
          <i class="el-icon-fa-bars"></i>
          <span slot="title">
            <app-i18n code="entities.kostenstelle.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/hauptkostenstelle')"
          :route="{ path: '/hauptkostenstelle' }"
          index="/hauptkostenstelle"
          v-if="hasPermissionToHauptkostenstelle"
        >
          <i class="el-icon-fa-file"></i>
          <span slot="title">
            <app-i18n code="entities.hauptkostenstelle.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/vertragsart')"
          :route="{ path: '/vertragsart' }"
          index="/vertragsart"
          v-if="hasPermissionToVertragsart"
        >
          <i class="el-icon-fa-file-text-o"></i>
          <span slot="title">
            <app-i18n code="entities.vertragsart.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/fakultaet')"
          :route="{ path: '/fakultaet' }"
          index="/fakultaet"
          v-if="hasPermissionToFakultaet"
        >
          <i class="el-icon-fa-building"></i>
          <span slot="title">
            <app-i18n code="entities.fakultaet.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/einstufung')"
          :route="{ path: '/einstufung' }"
          index="/einstufung"
          v-if="hasPermissionToEinstufung"
        >
          <i class="el-icon-fa-file"></i>
          <span slot="title">
            <app-i18n code="entities.einstufung.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/vorlage-dienstgeber')"
          :route="{ path: '/vorlage-dienstgeber' }"
          index="/vorlage-dienstgeber"
          v-if="hasPermissionToVorlageDienstgeber"
        >
          <i class="el-icon-fa-file-text-o"></i>
          <span slot="title">
            <app-i18n code="entities.vorlageDienstgeber.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/vorlage-dienstnehmer')"
          :route="{ path: '/vorlage-dienstnehmer' }"
          index="/vorlage-dienstnehmer"
          v-if="hasPermissionToVorlageDienstnehmer"
        >
          <i class="el-icon-fa-file-text"></i>
          <span slot="title">
            <app-i18n code="entities.vorlageDienstnehmer.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/staatsbuergerschaft')"
          :route="{ path: '/staatsbuergerschaft' }"
          index="/staatsbuergerschaft"
          v-if="hasPermissionToStaatsbuergerschaft"
        >
          <i class="el-icon-fa-flag"></i>
          <span slot="title">
            <app-i18n code="entities.staatsbuergerschaft.menu"></app-i18n>
          </span>
        </el-menu-item>
        <!--  <el-menu-item
          :class="classFor('/knowledge-base')"
          :route="{ path: '/knowledge-base' }"
          index="/knowledge-base"
          v-if="hasPermissionToKnowledgeBase"
        >
          <i class="el-icon-fa-question-circle"></i>
          <span slot="title">
            <app-i18n code="entities.knowledgeBase.menu"></app-i18n>
          </span>
        </el-menu-item> -->
        <el-menu-item
          :class="classFor('/user')"
          :route="{ path: '/user' }"
          index="/user"
          v-if="hasPermissionToUser"
        >
          <i class="el-icon-fa-user-plus"></i>
          <span slot="title">
            <app-i18n code="user.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/audit-logs')"
          :route="{ path: '/audit-logs' }"
          index="/audit-logs"
          v-if="hasPermissionToAuditLog"
        >
          <i class="el-icon-fa-history"></i>
          <span slot="title">
            <app-i18n code="auditLog.menu"></app-i18n>
          </span>
        </el-menu-item>
        <el-menu-item
          :class="classFor('/settings')"
          :route="{ path: '/settings' }"
          index="/settings"
          v-if="hasPermissionToSettings"
        >
          <i class="el-icon-setting"></i>
          <span slot="title">
            <app-i18n code="settings.menu"></app-i18n>
          </span>
        </el-menu-item>
      </div>
    </el-menu>
  </el-aside>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SettingsPermissions } from '@/modules/settings/settings-permissions';
import { AuditLogPermissions } from '@/modules/audit-log/audit-log-permissions';
import { UserPermissions } from '@/modules/user/user-permissions';
import { VorlageDienstgeberPermissions } from '@/modules/vorlage-dienstgeber/vorlage-dienstgeber-permissions';
import { KnowledgeBasePermissions } from '@/modules/knowledge-base/knowledge-base-permissions';
import { MandantPermissions } from '@/modules/mandant/mandant-permissions';
import { VerwendungKostenstellePermissions } from '@/modules/verwendung-kostenstelle/verwendung-kostenstelle-permissions';
import { MitarbeiterPermissions } from '@/modules/mitarbeiter/mitarbeiter-permissions';
import { KostentraegerPermissions } from '@/modules/kostentraeger/kostentraeger-permissions';
import { VertragsartPermissions } from '@/modules/vertragsart/vertragsart-permissions';
import { FakultaetPermissions } from '@/modules/fakultaet/fakultaet-permissions';
import { KostenstellePermissions } from '@/modules/kostenstelle/kostenstelle-permissions';
import { StaatsbuergerschaftPermissions } from '@/modules/staatsbuergerschaft/staatsbuergerschaft-permissions';
import { StatistikAustriaPermissions } from '@/modules/statistik-austria/statistik-austria-permissions';
import { VorlageDienstnehmerPermissions } from '@/modules/vorlage-dienstnehmer/vorlage-dienstnehmer-permissions';
import { HauptkostenstellePermissions } from '@/modules/hauptkostenstelle/hauptkostenstelle-permissions';
import { AenderungPermissions } from '@/modules/aenderung/aenderung-permissions';
import { AuswertungPermissions } from '@/modules/auswertung/auswertung-permissions';
import { EinstufungPermissions } from '@/modules/einstufung/einstufung-permissions';

export default {
  name: 'app-menu',

  data() {
    return {
      showAdvanced: false,
    };
  },

  computed: {
    ...mapGetters({
      collapsed: 'layout/menuCollapsed',
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      isAdmin: 'auth/isAdmin',
    }),

    hasPermissionToSettings() {
      return new SettingsPermissions(this.currentUser).navigate;
    },
    hasPermissionToAuditLog() {
      return new AuditLogPermissions(this.currentUser).navigate;
    },
    hasPermissionToUser() {
      return new UserPermissions(this.currentUser).navigate;
    },
    hasPermissionToVorlageDienstgeber() {
      return new VorlageDienstgeberPermissions(this.currentUser).navigate;
    },
    hasPermissionToVorlageDienstnehmer() {
      return new VorlageDienstnehmerPermissions(this.currentUser).navigate;
    },
    hasPermissionToKnowledgeBase() {
      return new KnowledgeBasePermissions(this.currentUser).navigate;
    },
    hasPermissionToMandant() {
      return new MandantPermissions(this.currentUser).navigate;
    },
    hasPermissionToMitarbeiter() {
      return new MitarbeiterPermissions(this.currentUser).navigate;
    },
    hasPermissionToKostentraeger() {
      return new KostentraegerPermissions(this.currentUser).navigate;
    },
    hasPermissionToVerwendungKostenstelle() {
      return new VerwendungKostenstellePermissions(this.currentUser).navigate;
    },
    hasPermissionToVertragsart() {
      return new VertragsartPermissions(this.currentUser).navigate;
    },
    hasPermissionToFakultaet() {
      return new FakultaetPermissions(this.currentUser).navigate;
    },
    hasPermissionToKostenstelle() {
      return new KostenstellePermissions(this.currentUser).navigate;
    },
    hasPermissionToHauptkostenstelle() {
      return new HauptkostenstellePermissions(this.currentUser).navigate;
    },
    hasPermissionToEinstufung() {
      return new EinstufungPermissions(this.currentUser).navigate;
    },
    hasPermissionToStaatsbuergerschaft() {
      return new StaatsbuergerschaftPermissions(this.currentUser).navigate;
    },
    hasPermissionToStatistikAustria() {
      return new StatistikAustriaPermissions(this.currentUser).navigate;
    },
    hasPermissionToAenderung() {
      return new AenderungPermissions(this.currentUser).navigate;
    },
    hasPermissionToAuswertung() {
      return new AuswertungPermissions(this.currentUser).navigate;
    },

    asideWidth() {
      if (this.isMobile && !this.collapsed) {
        return '100%';
      }
      if (!this.collapsed) {
        return '210px';
      }
      if (this.isMobile && this.collapsed) {
        return '0px';
      }
      return '70px';
    },
  },

  created() {
    this.getElementUiDateFormat();
  },

  methods: {
    ...mapActions({
      collapseMenu: 'layout/collapseMenu',
      getElementUiDateFormat: 'layout/getElementUiDateFormat',
    }),

    collapseMenuIfMobile() {
      if (this.isMobile) {
        this.collapseMenu();
      }
    },

    classFor(path, exact = false) {
      if (exact) {
        return {
          active: this.$route.path === path,
        };
      }

      const routePath = this.$route.path;
      const active = routePath === path || routePath.startsWith(path + '/');

      return {
        active,
      };
    },
  },
};
</script>

<style scoped>
.el-menu.collapsed span {
  visibility: hidden;
}
</style>
