import authAxios from '@/shared/axios/auth-axios';

export class FunktionService {
  static async update(id, data) {
    const body = {
      id,
      data,
    };

    const response = await authAxios.put(`/funktion/${id}`, body);

    return response.data;
  }

  static async create(data) {
    const body = {
      data,
    };

    const response = await authAxios.post(`/funktion`, body);

    return response.data;
  }

  static async list(filter, orderBy, limit, offset) {
    const params = {
      filter,
      orderBy,
      limit,
      offset,
    };

    const response = await authAxios.get(`/funktion`, {
      params,
    });

    return response.data;
  }

  static async listAutocomplete(limit, query, parentId, filter) {
    const params = {
      limit,
      query,
      parentId,
      filter,
    };

    const response = await authAxios.get(`/funktion/autocomplete`, {
      params,
    });

    return response.data;
  }
}
