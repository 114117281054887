<template>
  <div>
    <el-dialog :visible.sync="dialogVisible" width="50%">
      <el-table :border="true" :data="rows" ref="table" row-key="id">
        <el-table-column
          :show-overflow-tooltip="true"
          :label="fields.approver.label"
          :prop="fields.approver.name"
          width="350"
        >
          <template v-slot="scope">
            <app-list-item-relation-to-one
              :label="fields.approver.label"
              :readPermission="fields.approver.readPermission"
              :url="false"
              :value="presenter(scope.row, 'approver')"
            ></app-list-item-relation-to-one>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          :label="fields.status.label"
          :prop="fields.status.name"
          width="150"
        >
          <template v-slot="scope">
            <el-tag
              :type="getStatusTagType(scope.row[fields.status.name])"
              v-if="isStatusNull(scope.row[fields.status.name])"
            >
              {{ presenter(scope.row, 'status') }}
            </el-tag>
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          :label="fields.comment.label"
          :prop="fields.comment.name"
        >
          <template v-slot="scope">
            {{ presenter(scope.row, 'comment') }}
          </template>
        </el-table-column>

        <el-table-column
          :show-overflow-tooltip="true"
          :label="fields.updatedAt.label"
          :prop="fields.updatedAt.name"
          width="150"
        >
          <template v-slot="scope">
            {{ presenter(scope.row, 'updatedAt') }}
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="doClose">
          <app-i18n code="common.close"></app-i18n>
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { ApprovalRequestModel } from '@/modules/approval-request/approval-request-model';
import { mapGetters } from 'vuex';

const { fields } = ApprovalRequestModel;

export default {
  name: 'app-approval-request-list-table',

  props: ['rows', 'visible'],

  data() {
    return {};
  },

  computed: {
    ...mapGetters({
      isMobile: 'layout/isMobile',
      currentUser: 'auth/currentUser',
      paginationLayout: 'layout/paginationLayout',
    }),

    dialogVisible: {
      get: function () {
        return this.visible;
      },

      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },

    fields() {
      return fields;
    },
  },

  methods: {
    presenter(row, fieldName) {
      return ApprovalRequestModel.presenter(row, fieldName);
    },

    doClose() {
      this.$emit('close');
    },

    isStatusNull(status) {
      return status === null ? false : true;
    },

    getStatusTagType(status) {
      if (status === 'approved') {
        return 'success';
      }
      if (status === 'revisionRequested') {
        return 'warning';
      }
      if (status === 'rejected') {
        return 'danger';
      }
      if (status === 'requested') {
        return 'info';
      }
      return '';
    },
  },
};
</script>

<style></style>
