<template>
  <div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      v-on:close="handleTempFiles"
      width="20%"
    >
      <el-form
        label-position="top"
        :label-width="labelWidthForm"
        :model="model"
        :rules="rules"
        @submit.native.prevent="doSubmit"
        class="form"
        ref="form"
        v-if="model"
      >
        <div style="padding-bottom: 20px">
          <el-button :disabled="saveLoading" @click="doNewStart()" type="info">
            Neu Bildungskarenz
          </el-button>
        </div>
        <el-form-item
          :label="fields.bildungskarenzBeginn.label"
          :prop="fields.bildungskarenzBeginn.name"
          :required="fields.bildungskarenzBeginn.required"
        >
          <el-date-picker
            :format="elementUiDateFormat"
            placeholder
            v-model="model[fields.bildungskarenzBeginn.name]"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          :label="fields.bildungskarenzEnde.label"
          :prop="fields.bildungskarenzEnde.name"
          :required="fields.bildungskarenzEnde.required"
        >
          <el-date-picker
            :format="elementUiDateFormat"
            placeholder
            v-model="model[fields.bildungskarenzEnde.name]"
          ></el-date-picker>
        </el-form-item>
        <el-form-item
          :label="fields.karenzDoku.label"
          :prop="fields.karenzDoku.name"
        >
          <app-file-upload
            :max="fields.karenzDoku.max"
            :storage="fields.karenzDoku.storage"
            :formats="fields.karenzDoku.formats"
            :permissions="fields.karenzDoku.permissions"
            v-model="model[fields.karenzDoku.name]"
          ></app-file-upload>
        </el-form-item>
        <el-form-item>
          <div class="form-buttons">
            <el-button
              :disabled="saveLoading"
              @click="doSubmit"
              icon="el-icon-fa-floppy-o"
              type="primary"
            >
              <app-i18n code="common.save"></app-i18n>
            </el-button>
            <el-button
              :disabled="saveLoading"
              @click="doCancel"
              icon="el-icon-fa-close"
            >
              <app-i18n code="common.cancel"></app-i18n>
            </el-button>
          </div>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { FileUploader } from '@/shared/file-upload/file-uploader';
import { FormSchema } from '@/shared/form/form-schema';
import { MitarbeiterAnstellungModel } from '@/modules/mitarbeiter/mitarbeiter-anstellung-model';
const { fields } = MitarbeiterAnstellungModel;
const formSchema = new FormSchema([
  fields.bildungskarenzBeginn,
  fields.bildungskarenzEnde,
  fields.karenzDoku,
]);
export default {
  name: 'app-mitarbeiter-anstellung-bildungskarenz-form-modal',
  props: ['anstellung', 'visible'],
  data() {
    return {
      rules: formSchema.rules(),
      model: {},
      saveLoading: false,
      isReset: false,
    };
  },
  created() {
    this.model = formSchema.initialValues(this.anstellung || {});
  },
  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),
    dialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (!value) {
          this.$emit('close');
        }
      },
    },
    pendingFiles() {
      if (!this.model) return [];
      return this.model.karenzDoku.filter((f) => f.new);
    },
    fields() {
      return fields;
    },
    isEditing() {
      return (
        !!this.anstellung.bildungskarenzBeginn ||
        !!this.anstellung.bildungskarenzEnde
      );
    },
    title() {
      return 'Bildungskarenz';
    },
  },
  methods: {
    doNewStart() {
      this.model = formSchema.initialValues({});
      this.$refs.form.resetFields();
      this.isReset = true;
    },
    async doSubmit() {
      const data = await this.validateForm();
      if (this.isEditing && !this.isReset) {
        return this.$emit('doUpdateBildungskarenz', data);
      } else {
        return this.$emit('doSetBildungskarenz', data);
      }
    },
    doRemove() {
      return this.$emit('doRemoveBildungskarenz');
    },
    async validateForm() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }
      return formSchema.cast(this.model);
    },
    doCancel() {
      this.handleTempFiles();
      this.dialogVisible = false;
    },
    handleTempFiles() {
      if (this.pendingFiles.length > 0) {
        FileUploader.removePendingFiles(this.pendingFiles);
      }
    },
  },
};
</script>
<style></style>
