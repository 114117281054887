<template>
  <div>
    <el-row>
      <el-col>
        <div>
          <el-form
            label-position="top"
            :label-width="labelWidthForm"
            :model="model"
            :rules="rules"
            @submit.native.prevent="doSubmit"
            class="form"
            ref="form"
          >
            <el-form-item
              :label="fields.type.label"
              :prop="fields.type.name"
              :required="fields.type.required"
            >
              <el-select
                placeholder
                v-model="model[fields.type.name]"
                default-first-option
              >
                <el-option :value="undefined">--</el-option>
                <el-option
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                  v-for="option in fields.type.options"
                ></el-option>
              </el-select>
            </el-form-item>

            <div v-if="model.type == 'mutterschutz'">
              <el-form-item
                :label="fields.schutzfrist.label"
                :prop="fields.schutzfrist.name"
                :required="fields.schutzfrist.required"
              >
                <el-select
                  @change="handleGeplantenGeburtsterminChange()"
                  placeholder
                  v-model="model[fields.schutzfrist.name]"
                  default-first-option
                >
                  <el-option :value="undefined">--</el-option>
                  <el-option
                    :key="option.id"
                    :label="option.label"
                    :value="option.id"
                    v-for="option in fields.schutzfrist.options"
                  ></el-option>
                </el-select>
              </el-form-item>
              <el-form-item
                :label="fields.geplantenGeburtstermin.label"
                :prop="fields.geplantenGeburtstermin.name"
                :required="fields.schutzfrist.required"
              >
                <el-date-picker
                  @change="handleGeplantenGeburtsterminChange()"
                  :format="elementUiDateFormat"
                  placeholder
                  v-model="model[fields.geplantenGeburtstermin.name]"
                ></el-date-picker>
              </el-form-item>
            </div>
            <div v-if="model.type">
              <el-form-item
                :label="fields.beginn.label"
                :prop="fields.beginn.name"
                :required="fields.beginn.required"
              >
                <el-date-picker
                  :format="elementUiDateFormat"
                  placeholder
                  v-model="model[fields.beginn.name]"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                :label="fields.ende.label"
                :prop="fields.ende.name"
                :required="fields.ende.required"
              >
                <el-date-picker
                  :format="elementUiDateFormat"
                  placeholder
                  v-model="model[fields.ende.name]"
                ></el-date-picker>
              </el-form-item>
              <el-form-item
                :label="fields.karenzvertretungMitarbeiter.label"
                :prop="fields.karenzvertretungMitarbeiter.name"
                :required="fields.karenzvertretungMitarbeiter.required"
                v-if="
                  model.type != 'elternteilzeit' ||
                  model.type != 'bildungsteilzeit'
                "
              >
                <app-mitarbeiter-autocomplete-input
                  :fetchFn="fields.karenzvertretungMitarbeiter.fetchFn"
                  :mapperFn="fields.karenzvertretungMitarbeiter.mapperFn"
                  mode="single"
                  v-model="model[fields.karenzvertretungMitarbeiter.name]"
                ></app-mitarbeiter-autocomplete-input>
              </el-form-item>
              <el-form-item
                :label="fields.bestaetigung.label"
                :prop="fields.bestaetigung.name"
                :required="fields.bestaetigung.required"
              >
                <app-file-upload
                  :max="fields.bestaetigung.max"
                  :storage="fields.bestaetigung.storage"
                  :formats="fields.bestaetigung.formats"
                  :permissions="fields.bestaetigung.permissions"
                  v-model="model[fields.bestaetigung.name]"
                ></app-file-upload>
              </el-form-item>
            </div>

            <el-form-item>
              <div class="form-buttons">
                <el-button
                  :disabled="saveLoading"
                  @click="doSubmit"
                  icon="el-icon-fa-floppy-o"
                  type="primary"
                  v-if="model.type"
                >
                  <app-i18n code="common.save"></app-i18n>
                </el-button>
                <el-button
                  :disabled="saveLoading"
                  @click="doCancel"
                  icon="el-icon-fa-close"
                >
                  <app-i18n code="common.cancel"></app-i18n>
                </el-button>
              </div>
            </el-form-item>
          </el-form>
        </div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import { FormSchema } from '@/shared/form/form-schema';
import { KarenzModel } from '@/modules/karenz/karenz-model';
import { i18n } from '@/i18n';

const { fields } = KarenzModel;
const formSchema = new FormSchema([
  fields.id,
  fields.mitarbeiter,
  fields.beginn,
  fields.ende,
  fields.type,
  fields.schutzfrist,
  fields.geplantenGeburtstermin,
  fields.karenzvertretungMitarbeiter,
  fields.bestaetigung,
]);
export default {
  name: 'app-karenz-form',

  props: ['isEditing', 'record', 'saveLoading'],

  data() {
    return {
      rules: formSchema.rules(),
      model: null,
      pendingFiles: [],
    };
  },

  created() {
    this.model = formSchema.initialValues(this.record || {});
  },

  watch: {
    'model.bestaetigung': {
      handler: function (newVal) {
        this.pendingFiles = newVal.filter((f) => f.new);
      },
    },
    //watching for karenz type change and acting
    'model.type': {
      handler: async function (newVal, oldVal) {
        if (!oldVal) {
          return; //if there is no old value - (nothing was selected) we don't show confirmation dialog
        }

        if (newVal != oldVal) {
          try {
            await this.$confirm(
              i18n('entities.karenz.confirmation.message'),
              i18n('common.confirm'),
              {
                confirmButtonText: i18n('common.yes'),
                cancelButtonText: i18n('common.no'),
                type: 'warning',
              },
            );

            this.$emit('removePendingFiles', this.pendingFiles);
            this.pendingFiles = []; //clear pending files if there is any

            if (!this.record) {
              this.model = formSchema.initialValues({ type: newVal } || {});
            } else {
              this.model = formSchema.initialValues(this.record || {});
            }
            await this.clearTableValidate();
          } catch (error) {
            // no
          }
        }
      },
    },
  },

  computed: {
    ...mapGetters({
      labelPosition: 'layout/labelPosition',
      labelWidthForm: 'layout/labelWidthForm',
      elementUiDateFormat: 'layout/elementUiDateFormat',
    }),

    fields() {
      return fields;
    },
  },

  methods: {
    doCancel() {
      this.$emit('cancel', this.pendingFiles);
    },
    async doSubmit() {
      try {
        await this.$refs.form.validate();
      } catch (error) {
        return;
      }

      const { id, ...values } = formSchema.cast(this.model);

      return this.$emit('submit', {
        id,
        values,
      });
    },
    async clearTableValidate() {
      await this.$refs.form.clearValidate();
    },

    /* -------------------------------------- MUTTERScHUTZ -------------------------------------- */

    handleGeplantenGeburtsterminChange() {
      if (!this.model.schutzfrist) {
        this.$refs.form.fields.find((f) => f.prop == 'schutzfrist').validate();
        return;
      }
      if (!this.model.geplantenGeburtstermin) {
        return;
      }
      const { beginn, ende } = Vue.DateHelper.calculateMutterschutzDates(
        this.model.geplantenGeburtstermin,
        this.model.schutzfrist,
      );
      this.model.beginn = beginn;
      this.model.ende = ende;
    },

    /* -------------------------------------- MUTTERScHUTZ -------------------------------------- */
  },
};
</script>

<style></style>
