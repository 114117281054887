import { render, staticRenderFns } from "./vertragsaenderung-form-modal.vue?vue&type=template&id=903baf96&scoped=true&"
import script from "./vertragsaenderung-form-modal.vue?vue&type=script&lang=js&"
export * from "./vertragsaenderung-form-modal.vue?vue&type=script&lang=js&"
import style0 from "./vertragsaenderung-form-modal.vue?vue&type=style&index=0&id=903baf96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "903baf96",
  null
  
)

export default component.exports