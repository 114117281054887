import { MitarbeiterService } from '@/modules/mitarbeiter/mitarbeiter-service';
import Errors from '@/shared/error/errors';
import { routerAsync } from '@/app-module';
import Message from '@/shared/message/message';
import { i18n } from '@/i18n';
import Vue from 'vue';

function yearsBetweenDates(date1, date2) {
  var birthDate = date1;
  var age = date2.getFullYear() - birthDate.getFullYear();
  var m = date2.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && date2.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

export default {
  namespaced: true,
  state: {
    findLoading: false,
    saveLoading: false,
    loading: false,
    notizLoading: false,
    notizSaving: false,
    anstellungLoading: false,
    record: null,
    formModel: null,
    anstellungNew: null,
    grundgehaltNew: [],
    gehaltszulageNew: [],
    notiz: null,
    viewMode: false,
    reActivationStarted: false,
    hasPermissionToEditInactive: false,
  },
  getters: {
    record: (state) => state.record,
    id: (state) => (state.record ? state.record.id : null),
    findLoading: (state) => !!state.findLoading,
    saveLoading: (state) => !!state.saveLoading,
    showSpinner: (state) =>
      state.loading || state.saveLoading || state.findLoading,
    anstellungLoading: (state) => !!state.anstellungLoading,
    anstellung: (state) =>
      state.record && state.record.anstellung ? state.record.anstellung : [],
    grundgehalt: (state) =>
      state.record && state.record.anstellung.grundgehalt
        ? state.record.anstellung.grundgehalt
        : [],
    gehaltszulage: (state) =>
      state.record && state.record.anstellung.gehaltszulage
        ? state.record.anstellung.gehaltszulage
        : [],
    anstellungNew: (state) => state.anstellungNew || null,
    grundgehaltNew: (state) => state.grundgehaltNew || null,
    gehaltszulageNew: (state) => state.gehaltszulageNew || null,
    formModel: (state) => state.formModel,
    age: (state) => {
      if (state.record && state.record.geburtsdatum) {
        return yearsBetweenDates(
          Vue.DateHelper.noTimeDate(state.record.geburtsdatum),
          Vue.DateHelper.noTimeDate(new Date()),
        );
      } else if (state.formModel && state.formModel.geburtsdatum) {
        return yearsBetweenDates(
          Vue.DateHelper.noTimeDate(state.formModel.geburtsdatum),
          Vue.DateHelper.noTimeDate(new Date()),
        );
      } else {
        return 0;
      }
    },
    dienstjahr: (state, getters) => {
      if (getters.anstellung && getters.anstellung.eintrittsdatum) {
        return yearsBetweenDates(
          Vue.DateHelper.noTimeDate(getters.anstellung.eintrittsdatum),
          Vue.DateHelper.noTimeDate(new Date()),
        );
      } else {
        return 0;
      }
    },
    pendingFiles: (state, getters) => {
      if (!getters.formModel) return [];
      let unsaved = [];
      const persoenlicheData = getters.formModel.persoenlicheData.filter(
        (f) => f.new,
      );
      const mitteilung = getters.formModel.mitteilung.filter((f) => f.new);
      const karenzDoku = getters.formModel.karenzDoku.filter((f) => f.new);
      const krankmeldung = getters.formModel.karenzDoku.filter((f) => f.new);
      unsaved = unsaved.concat(persoenlicheData);
      unsaved = unsaved.concat(mitteilung);
      unsaved = unsaved.concat(karenzDoku);
      unsaved = unsaved.concat(krankmeldung);
      return unsaved;
    },
    notiz: (state) => state.notiz,
    eintrittsdatum: (state) => {
      if (
        state.record &&
        state.record.anstellung &&
        state.record.anstellung.eintrittsdatum
      ) {
        return Vue.DateHelper.noTimeDate(
          state.record.anstellung.eintrittsdatum,
        );
      } else if (state.anstellungNew && state.anstellungNew.eintrittsdatum) {
        return Vue.DateHelper.noTimeDate(state.anstellungNew.eintrittsdatum);
      } else return null;
    },
    austrittsdatum: (state) => {
      if (
        state.record &&
        state.record.anstellung &&
        state.record.anstellung.austrittsdatum
      ) {
        return state.record.anstellung.austrittsdatum;
      } else if (state.anstellungNew && state.anstellungNew.austrittsdatum) {
        return state.anstellungNew.austrittsdatum;
      } else return null;
    },
    status: (state) => {
      return state.record && state.record.status ? state.record.status : null;
    },
    isActive: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'active'
        : false;
    },
    isInactive: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'inactive'
        : false;
    },
    isInPlan: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'plan'
        : false;
    },
    isInMutterschutz: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'mutterschutz'
        : false;
    },
    isInMutterkarenz: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'mutterkarenz'
        : false;
    },
    isInBildungskarenz: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'bildungskarenz'
        : false;
    },
    isInPapamonat: (state) => {
      return state.record && state.record.status
        ? state.record.status === 'papamonat'
        : false;
    },
    viewMode: (state) => state.viewMode,
    reActivationStarted: (state) => state.reActivationStarted,
    isDisabled: (state, getters) => {
      return (
        (getters.viewMode || getters.isInactive) && !getters.reActivationStarted
      );
    },
    isFormDisabled: (state, getters) => {
      return (
        (getters.viewMode || getters.isInactive) &&
        !getters.reActivationStarted &&
        !state.hasPermissionToEditInactive
      );
    },
  },
  mutations: {
    RESET(state) {
      state.findLoading = false;
      state.saveLoading = false;
      state.loading = false;
      state.notizLoading = false;
      state.notizSaving = false;
      state.record = null;
      state.anstellungNew = null;
      state.grundgehaltNew = null;
      state.gehaltszulageNew = null;
      state.formModel = null;
      state.viewMode = false;
      state.reActivationStarted = false;
      state.hasPermissionToEditInactive = false;
    },
    FIND_STARTED(state) {
      state.record = null;
      state.formModel = null;
      state.findLoading = true;
      state.loading = false;
      state.anstellungNew = null;
      state.grundgehaltNew = null;
      state.gehaltszulageNew = null;
      state.formModel = null;
      state.viewMode = false;
      state.reActivationStarted = false;
      state.hasPermissionToEditInactive = false;
    },
    FIND_SUCCESS(state, payload) {
      state.record = payload;
      state.findLoading = false;
    },
    FIND_ERROR(state) {
      state.record = null;
      state.formModel = null;
      state.findLoading = false;
      state.loading = false;
      state.anstellungNew = null;
      state.grundgehaltNew = null;
      state.gehaltszulageNew = null;
      state.formModel = null;
      state.reActivationStarted = false;
    },
    CREATE_STARTED(state) {
      state.saveLoading = true;
    },
    CREATE_SUCCESS(state) {
      state.saveLoading = false;
    },
    CREATE_ERROR(state) {
      state.saveLoading = false;
    },
    UPDATE_STARTED(state) {
      state.saveLoading = true;
    },
    UPDATE_SUCCESS(state) {
      state.formModel = null;
      state.saveLoading = false;
    },
    UPDATE_ERROR(state) {
      state.saveLoading = false;
    },
    FETCH_NOTIZ_STARTED(state) {
      state.notiz = null;
      state.notizLoading = true;
    },
    FETCH_NOTIZ_SUCCESS(state, payload) {
      state.notiz = payload;
      state.notizLoading = false;
    },
    FETCH_NOTIZ_ERROR(state) {
      state.notiz = null;
      state.notizLoading = false;
    },
    CREATE_NOTIZ_STARTED(state) {
      state.notizSaving = true;
    },
    CREATE_NOTIZ_SUCCESS(state, payload) {
      state.notiz = payload;
      state.notizSaving = false;
    },
    CREATE_NOTIZ_ERROR(state) {
      state.notizSaving = false;
    },
    SET_GRUNDGEHALT(state, payload) {
      state.grundgehaltNew = payload;
    },
    SET_GEHALTSZULAGE(state, payload) {
      state.gehaltszulageNew = payload;
    },
    SET_ANSTELLUNG(state, payload) {
      state.anstellungNew = payload;
    },
    SET_FORM_MODEL(state, payload) {
      state.formModel = payload;
    },
    SET_VIEW_MODE(state, payload) {
      state.viewMode = payload;
    },
    SET_SPINNER(state, payload) {
      state.loading = payload;
    },
    SHOW_MESSAGE(state, value) {
      Message.warning(i18n(value));
    },
    RESET_MA_STATUS(state) {
      state.reActivationStarted = true;
      state.record.status = null;
    },
    SET_EDIT_INACTIVE_PERMISSION(state, payload) {
      state.hasPermissionToEditInactive = payload;
    },
  },
  actions: {
    doNew({ commit }) {
      commit('RESET');
    },
    async showMessage({ commit }, value) {
      commit('SHOW_MESSAGE', value);
    },
    async doSetSpinner({ commit }, value) {
      commit('SET_SPINNER', value);
    },
    async doSetGrundgehalt({ commit }, value) {
      commit('SET_GRUNDGEHALT', value);
    },
    async doSetGehaltszulage({ commit }, value) {
      commit('SET_GEHALTSZULAGE', value);
    },
    async doSetAnstellung({ commit }, value) {
      commit('SET_ANSTELLUNG', value);
    },
    async doSetFormModel({ commit }, payload) {
      commit('SET_FORM_MODEL', payload);
    },
    async doSetViewMode({ commit }, payload) {
      commit('SET_VIEW_MODE', payload);
    },
    async doResetMitarbeiterStatus({ commit }, payload) {
      commit('RESET_MA_STATUS', payload);
    },
    async doSetEditInactivePermission({ commit }, payload) {
      commit('SET_EDIT_INACTIVE_PERMISSION', payload);
    },
    async doFind({ dispatch, commit }, id) {
      try {
        dispatch('mitarbeiter/anstellung/doReset', null, {
          root: true,
        });
        dispatch('vertrag/doReset', null, {
          root: true,
        });
        dispatch('vertragsergaenzung/doReset', null, {
          root: true,
        });
        dispatch('beendigung/doReset', null, {
          root: true,
        });

        commit('FIND_STARTED');
        const record = await MitarbeiterService.find(id);
        commit('FIND_SUCCESS', record);
        return record;
      } catch (error) {
        Errors.handle(error);
        commit('FIND_ERROR');
        routerAsync().push('/mitarbeiter');
      }
    },
    async doCreate({ commit, getters }, values) {
      try {
        commit('CREATE_STARTED');
        let anstellung = getters.anstellungNew;
        anstellung.grundgehalt = getters.grundgehaltNew;
        anstellung.gehaltszulage = getters.gehaltszulageNew;
        values.anstellung = anstellung;
        await MitarbeiterService.create(values);
        commit('CREATE_SUCCESS');
        Message.success(i18n('entities.mitarbeiter.create.success'));
        routerAsync().push('/mitarbeiter');
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_ERROR');
      }
    },
    async doUpdate({ commit, getters }, { id, values, reActivationStarted }) {
      try {
        commit('UPDATE_STARTED');
        let anstellung = getters.anstellungNew;
        anstellung.grundgehalt = getters.grundgehaltNew;
        anstellung.gehaltszulage = getters.gehaltszulageNew;
        values.anstellung = anstellung;
        await MitarbeiterService.update(id, values, reActivationStarted);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.mitarbeiter.update.success'));
        routerAsync().push('/mitarbeiter');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doAbmelden({ commit }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        await MitarbeiterService.abmelden(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.mitarbeiter.update.success'));
        routerAsync().push('/mitarbeiter');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    async doSetKarenzvertretung({ commit, getters }, { id, values }) {
      try {
        commit('UPDATE_STARTED');
        let anstellung = getters.anstellungNew;
        anstellung.grundgehalt = getters.grundgehaltNew;
        anstellung.gehaltszulage = getters.gehaltszulageNew;
        values.anstellung = anstellung;
        await MitarbeiterService.setKarenzvertretung(id, values);
        commit('UPDATE_SUCCESS');
        Message.success(i18n('entities.mitarbeiter.update.success'));
        routerAsync().push('/mitarbeiter');
      } catch (error) {
        Errors.handle(error);
        commit('UPDATE_ERROR');
      }
    },

    /* ---------------------------------------KARENZ------------------------------------- */

    async doFetchNotiz({ commit }, mitarbeiterId) {
      try {
        commit('FETCH_NOTIZ_STARTED');
        const records = await MitarbeiterService.listNotiz(mitarbeiterId);
        commit('FETCH_NOTIZ_SUCCESS', records);
      } catch (error) {
        Errors.handle(error);
        commit('FETCH_NOTIZ_ERROR');
      }
    },
    async doCreateNotiz({ commit }, values) {
      try {
        commit('CREATE_NOTIZ_STARTED');
        const records = await MitarbeiterService.createNotiz(values);
        commit('CREATE_NOTIZ_SUCCESS', records);
        Message.success(i18n('entities.mitarbeiter.createNotiz.success'));
      } catch (error) {
        Errors.handle(error);
        commit('CREATE_NOTIZ_ERROR');
      }
    },
  },
};
