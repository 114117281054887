const moment = require('moment');

const MS_PER_DAY = 1000 * 60 * 60 * 24;

export default {
  calculateAustrittsdatum(eintrittsdatum, dienstjahr, value) {
    let austrittsdatum = this.noTimeDate(new Date());
    const date = this.noTimeDate(new Date(value));
    if (this.areDatesInSameMonth(eintrittsdatum, value)) {
      return date;
    } else if (dienstjahr <= 2) {
      austrittsdatum = this.addWeeks(date, 6);
    } else if (dienstjahr >= 3 && dienstjahr < 6) {
      austrittsdatum = this.addMonth(date, 2);
    } else if (dienstjahr >= 6 && dienstjahr < 16) {
      austrittsdatum = this.addMonth(date, 3);
    } else if (dienstjahr > 16 && dienstjahr < 26) {
      austrittsdatum = this.addMonth(date, 4);
    } else if (dienstjahr > 26) {
      austrittsdatum = this.addMonth(date, 5);
    }
    const dayNumber = austrittsdatum.getDate();
    if (dayNumber <= 15) {
      const daysToAdd = 15 - dayNumber;
      austrittsdatum = this.addDays(austrittsdatum, daysToAdd);
    } else {
      const daysInMonth = this.getNumberOfDaysInMonth(austrittsdatum);
      const daysToAdd = daysInMonth - dayNumber;
      austrittsdatum = this.addDays(austrittsdatum, daysToAdd);
    }
    return austrittsdatum;
  },

  calculateMutterschutzDates(date, weeks) {
    let dateTmp = this.noTimeDate(new Date(date));
    return {
      beginn: this.deductWeeks(dateTmp, 8),
      ende: this.addWeeks(dateTmp, weeks),
    };
  },

  yearsBetweenDates(date1, date2) {
    var birthDate = date1;
    var age = date2.getFullYear() - birthDate.getFullYear();
    var m = date2.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && date2.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  },

  getMitarbeiterAge(birthDate) {
    return this.yearsBetweenDates(
      this.noTimeDate(new Date(birthDate)),
      this.noTimeDate(new Date()),
    );
  },

  weeksBetween(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  },

  monthsBetween(startDate, endDate) {
    return parseInt(
      moment(new Date(endDate))
        .diff(new Date(startDate), 'months', true)
        .toString(),
    );
  },

  deductWeeks(date, weeks) {
    const tmpDate = this.noTimeDate(new Date(date));
    return new Date(tmpDate.setDate(tmpDate.getDate() - weeks * 7));
  },

  //----ADDING-----

  addDays(date, days) {
    const tmpDate = this.noTimeDate(new Date(date));
    return new Date(tmpDate.setDate(tmpDate.getDate() + days));
  },

  deductDays(date, days) {
    let result = this.noTimeDate(date);
    return new Date(result.setDate(result.getDate() - days));
  },

  addWeeks(date, weeks) {
    const tmpDate = this.noTimeDate(new Date(date));
    return new Date(tmpDate.setDate(tmpDate.getDate() + weeks * 7));
  },

  addMonth(date, months) {
    let result = this.noTimeDate(new Date(date));
    let tmpDate = this.noTimeDate(new Date(date));
    /*     var d = tmpDate.getDate(); */
    result.setMonth(tmpDate.getMonth() + +months);
    /*     if (result.getDate() != d) {
      result.setDate(0);
    } */
    return result;
  },

  deductMonth(date, months) {
    let result = this.noTimeDate(new Date(date));
    let tmpDate = this.noTimeDate(new Date(date));
    /*    var d = tmpDate.getDate(); */
    result.setMonth(tmpDate.getMonth() - months);
    /*   if (result.getDate() != d) {
      result.setDate(0);
    } */
    return result;
  },

  //----ADDING-----

  //----CHECKS-----

  isFirstDayInMonth(date) {
    let tmpDate = this.noTimeDate(new Date(date));
    const firstDay = new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 1);
    return tmpDate.getTime() == firstDay.getTime();
  },

  isLastDayInMonth(date) {
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return date.getTime() == lastDay.getTime();
  },

  isInCurrentMonth(date) {
    date = this.noTimeDate(new Date(date));
    const today = this.noTimeDate(new Date());
    return (
      date.getFullYear() == today.getFullYear() &&
      date.getMonth() == today.getMonth()
    );
  },

  isBetweenDates(date, start, end) {
    return moment(date).isBetween(start, end);
  },

  isBefore(date1, date2) {
    return moment(date1).isBefore(date2);
  },

  isAfter(date1, date2) {
    return moment(date1).isAfter(date2);
  },
  /* 
  isKstActive(kst, status, predictingDate) {
    const von = kst.von ? this.noTimeDate(kst.von) : null;
    const bis = kst.bis ? this.noTimeDate(kst.bis) : null;
    const comparingDate = predictingDate
      ? this.noTimeDate(predictingDate)
      : this.noTimeDate(new Date());

    const isVonActive =
      (this.isAfter(comparingDate, this.firstDayOfMonth(von)) ||
        this.areDatesInSameMonth(comparingDate, von)) &&
      bis === null;

    const isBisActive =
      (bis && this.isBefore(comparingDate, bis)) ||
      this.areDatesInSameMonth(comparingDate, bis);

    const isActive = isVonActive || isBisActive;

    if (status) {
      return isActive && kst.status === 'active';
    }
    return isActive;
  }, */

  isKstActive(f) {
    return (
      this.noTimeDate(new Date(f.von)) <= this.noTimeDate(new Date()) &&
      (f.bis === null ||
        this.noTimeDate(new Date(f.bis)) >= this.noTimeDate(new Date())) &&
      (f.status === 'active' || f.new === true)
    );
  },

  /* isKstActiveThisMonth(kst) {
    const von = kst.von ? this.noTimeDate(kst.von) : null;
    const bis = kst.bis ? this.noTimeDate(kst.bis) : null;
    const today = this.noTimeDate(new Date());

    const b = bis && bis.is

    return (
      this.isInPastOrToday(von) &&
      von <= today &&
      (bis === null || bis >= today) &&
      (kst.status === 'active' || kst.new === true)
    );
  }, */

  areDatesOnSameDay(first, second) {
    let firstTmp = this.noTimeDate(first);
    let secondTmp = this.noTimeDate(second);

    return (
      firstTmp.getFullYear() === secondTmp.getFullYear() &&
      firstTmp.getMonth() === secondTmp.getMonth() &&
      firstTmp.getDate() === secondTmp.getDate()
    );
  },

  areDatesInSameMonth(first, second) {
    let firstTmp = this.noTimeDate(first);
    let secondTmp = this.noTimeDate(second);

    return (
      firstTmp.getFullYear() === secondTmp.getFullYear() &&
      firstTmp.getMonth() === secondTmp.getMonth()
    );
  },

  isToday(date) {
    let firstTmp = this.noTimeDate(date);
    let secondTmp = this.noTimeDate(new Date());

    return (
      firstTmp.getFullYear() === secondTmp.getFullYear() &&
      firstTmp.getMonth() === secondTmp.getMonth() &&
      firstTmp.getDate() === secondTmp.getDate()
    );
  },

  isInFuture(date) {
    const tmpDate = this.noTimeDate(new Date(date));
    return tmpDate.setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0);
  },

  isInPast(date) {
    const tmpDate = this.noTimeDate(new Date(date));
    return tmpDate.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0);
  },

  isInPastOrToday(date) {
    return this.isInPast(date) && this.isToday(date);
  },

  //----CHECKS-----

  //----GET-----

  getMonthName(date) {
    const formatter = new Intl.DateTimeFormat('de', {
      month: 'long',
      year: 'numeric',
    });
    return formatter.format(new Date(date));
  },

  getNumberOfDaysInMonth(date) {
    const tmpDate = this.noTimeDate(new Date(date));
    return new Date(tmpDate.getFullYear(), date.getMonth() + 1, 0).getDate();
  },

  getWeeksCountOfMonth() {
    const today = this.noTimeDate(new Date());
    const firstDay = new Date(today.setDate(1)).getDay();
    const totalDays = new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0,
    ).getDate();
    return Math.ceil((firstDay + totalDays) / 7);
  },

  getDayNumber(date) {
    let tmpDate = this.noTimeDate(new Date(date));
    return tmpDate.getDate();
  },

  getMonthNumber(date) {
    let tmpDate = this.noTimeDate(new Date(date));
    return tmpDate.getMonth() + 1;
  },

  firstDayOfMonth(date) {
    const tmpDate = this.noTimeDate(new Date(date));
    return new Date(tmpDate.getFullYear(), tmpDate.getMonth(), 1);
  },

  lastDayOfMonth(date) {
    const tmpDate = this.noTimeDate(new Date(date));
    return new Date(tmpDate.getFullYear(), tmpDate.getMonth() + 1, 0);
  },

  sundaysInMonth() {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    var days = new Date(year, month, 0).getDate();
    var sundays = [8 - new Date(month + '/01/' + year).getDay()];
    for (var i = sundays[0] + 7; i <= days; i += 7) {
      sundays.push(i);
    }
    return sundays.length;
  },

  // a and b are javascript Date objects
  dateDiffInDays(date1, date2) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      date1.getFullYear(),
      date1.getMonth(),
      date1.getDate(),
    );
    const utc2 = Date.UTC(
      date2.getFullYear(),
      date2.getMonth(),
      date2.getDate(),
    );

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  },

  getWeekendCountBetweenDates(startDate, endDate) {
    startDate = this.noTimeDate(startDate);
    endDate = this.noTimeDate(endDate);
    var totalWeekends = 0;
    for (var i = startDate; i <= endDate; i.setDate(i.getDate() + 1)) {
      if (i.getDay() == 0 || i.getDay() == 1) totalWeekends++;
    }
    return totalWeekends;
  },

  getNumberOfWeekDays(start, end, dayNum) {
    start = this.noTimeDate(start);
    end = this.noTimeDate(end);
    // Sunday's num is 0 with Date.prototype.getDay.
    dayNum = dayNum || 0;
    // Calculate the number of days between start and end.
    var daysInInterval = Math.ceil(
      (end.getTime() - start.getTime()) / (1000 * 3600 * 24),
    );
    // Calculate the nb of days before the next target day (e.g. next Sunday after start).
    var toNextTargetDay = (7 + dayNum - start.getDay()) % 7;
    // Calculate the number of days from the first target day to the end.
    var daysFromFirstTargetDay = Math.max(daysInInterval - toNextTargetDay, 0);
    // Calculate the number of weeks (even partial) from the first target day to the end.
    return Math.ceil(daysFromFirstTargetDay / 7);
  },

  //----GET-----

  noTimeDate(dateTime) {
    dateTime = new Date(dateTime);
    let date = new Date(dateTime.getTime());
    date.setHours(0, 0, 0, 0);
    return date;
  },

  formatDate(date) {
    return moment(date).format('DD.MM.YYYY');
  },

  today() {
    return this.noTimeDate(new Date());
  },
};
